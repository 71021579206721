import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import SectionComponent from 'src/components/SectionComponent'

import { IOpenModalComponent } from '../../types'
import { Button, ButtonLink } from './../../styles'

import { WIDTH_MD, WIDTH_XL } from 'src/utils/breakpoints'

const Enquadramento = ({ openModal }: IOpenModalComponent) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='enquadramento'
      SectionStyles='bg-grayscale--100'
      RowStyles='justify-content-lg-center'
      minHeight={{ sm: '435px', md: '361px', lg: '427px', xl: '600px' }}
    >
      <div className='col-12 col-lg-10 col-xl-6'>
        <h3 className='fs-20 lh-25 fs-md-24 lh-md-30 mb-3 text-grayscale--500 font-sora'>
          Caso se enquadre nos itens 1 ou 2, você também se torna cliente
          <Link
            to='/pra-voce/relacionamento/inter-one/'
            title='Inter One'
            onClick={() => {
              openModal()
              sendDatalayerEvent({
                section: 'dobra_07',
                element_action: 'click button',
                element_name: 'Inter One',
                section_name: 'Caso se enquadre nos itens 1 ou 2, você também se torna cliente Inter One e passa a ter um advisor exclusivo para te ajudar a conquistar uma carteira de investimentos cada vez mais robusta.',
              })
            }}
          >
            Inter One
          </Link> e passa a ter um advisor exclusivo para te
          ajudar a conquistar uma carteira de investimentos cada vez mais robusta.
        </h3>
        <p className='fs-12 lh-15 text-grayscale--500 pb-md-0'>
          Para ter o cartão Platinum pelo valor das faturas, é preciso estar em dia com o pagamento delas, sem nenhum atraso.
          Além disso, o limite (acima de R$ 5 mil) precisa ter sido concedido pelo Inter na análise de crédito, sem contar o CDB Mais Limite.
        </p>
        <div className='d-flex justify-content-center'>
          { width < WIDTH_MD &&
            <ButtonLink
              href='https://intergo.app/c0ae23be'
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-4'
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Caso se enquadre nos itens 1 ou 2, você também se torna cliente Inter One e passa a ter um advisor exclusivo para te ajudar a conquistar uma carteira de investimentos cada vez mais robusta.',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </ButtonLink>
          }
          { width >= WIDTH_MD && width < WIDTH_XL &&
            <Button
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-4'
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Caso se enquadre nos itens 1 ou 2, você também se torna cliente Inter One e passa a ter um advisor exclusivo para te ajudar a conquistar uma carteira de investimentos cada vez mais robusta.',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </Button>
          }
        </div>
      </div>
    </SectionComponent>
  )
}

export default Enquadramento
