import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import usePageQuery from '../../pageQuery'

import { WIDTH_LG, WIDTH_MD } from 'src/utils/breakpoints'

import { IOpenModalComponent } from '../../types'
import { Button } from './../../styles'

const DescontosUnicos = ({ openModal }: IOpenModalComponent) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='descontos-unicos-platinum'
      bgMobile='descontosUnicosMobile'
      bgTablet='descontosUnicosTablet'
      bgDesktopLG='descontosUnicosDesktopLG'
      bgDesktopXL='descontosUnicosDesktopXL'
      bgDesktopXXL='descontosUnicosDesktopXL'
      SectionStyles='bg-grayscale--100 align-items-end align-items-lg-center'
      backgroundSize='contain'
      backgroundPosition={`${width < WIDTH_LG ? 'top center' : 'center right' }`}
      pageDataImages={data}
      minHeight={{ sm: '846px', md: '960px', lg: '595px', xl: '720px' }}
    >
      <div className='col-12 col-lg-6 col-xl-5'>
        <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fw-600 text-grayscale--500 font-sora mb-3'>
          Descontos únicos para quem é exclusivo
        </h2>
        <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500'>
          Aproveite os descontos em serviços para sua comodidade e bem-estar.
        </p>

        <span className='fs-16 lh-20 text-grayscale--500 fw-600 font-sora d-block mb-2'>10% de desconto no Decolar.com</span>
        <p className='fs-14 lh-17 text-grayscale--400 mb-md-4'>Monte seu roteiro e compre na maior agência online da América Latina.</p>

        <span className='fs-16 lh-20 text-grayscale--500 fw-600 font-sora d-block mb-2'>50% de desconto no Uber Pass por 6 meses</span>
        <p className='fs-14 lh-17 text-grayscale--400 mb-0 mb-md-5'>Tenha frete grátis no Uber Eats, descontos no UberX e suporte prioritário.</p>
        { width >= WIDTH_MD &&
          <div className='mt-2 d-flex justify-content-center justify-content-lg-start'>
            <Button
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none'
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_04',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Descontos únicos para quem é exclusivo',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </Button>
          </div>
        }
      </div>
    </SectionComponent>
  )
}

export default DescontosUnicos
