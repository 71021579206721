import React from 'react'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import BreadCrumb from 'src/components/Breadcrumb'
import ScrollTo from 'src/components/ScrollTo'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import Icon from 'src/components/UI/MarkdownIcon'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import usePageQuery from '../../pageQuery'

import { WIDTH_MD } from 'src/utils/breakpoints'

import { IOpenModalComponent } from '../../types'
import { Button, ButtonLink, Bread, ScrollLink } from './../../styles'

const GlobalAccountRemessas = ({ openModal }: IOpenModalComponent) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='cartao-platinum-inter'
      SectionStyles='bg-white align-items-start align-items-lg-center pt-md-2'
      minHeight={{ md: '567px', lg: 'calc(100vh - 73px)' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 mb-lg-5 order-md-last pt-md-5 mt-md-3 pt-lg-0 mt-lg-0 d-flex align-items-center justify-content-center justify-content-lg-end'>
        <Img className='d-md-none' fluid={data.beneficiosCartaoPlatinumMobile.fluid} alt='Cartão Inter Platinum' />
        <Img className='d-none d-md-block d-lg-none' fluid={data.beneficiosCartaoPlatinumTablet.fluid} alt='Cartão Inter Platinum' />
        <Img className='d-none d-lg-block d-xl-none' fluid={data.beneficiosCartaoPlatinumLG.fluid} alt='Cartão Inter Platinum' />
        <Img className='d-none d-xl-block' fluid={data.beneficiosCartaoPlatinumXL.fluid} alt='Cartão Inter Platinum' />
      </div>
      <div className='col-12 col-md-6 col-xl-5 pr-md-0 mb-lg-5'>
        <Bread>
          <BreadCrumb
            sectionName='Cartão Platinum Inter. Mais benefícios para você.'
            type='Pra você'
            product='Cartão de débito e crédito'
            text='Cartão Platinum'
            link='/pra-voce/cartoes/cartao-platinum/'
            styles='fs-14 d-none d-md-block mb-5 mt-lg-4'
          />
        </Bread>
        <h1 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 font-sora mb-3'>
          Cartão Platinum Inter. <span className='d-md-block d-lg-inline'>Mais benefícios</span> para você.
        </h1>
        <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--500'>
          Organize as suas viagens com um assistente pessoal disponível 24 horas e acesse ofertas especiais.
        </p>
        { width >= WIDTH_MD &&
          <Button
            title='Solicite seu cartão Platinum Inter'
            className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mb-lg-5'
            onClick={() => {
              openModal()
              sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                element_name: 'Solicite seu cartão Platinum Inter',
                section_name: 'Cartão Platinum Inter.Mais benefícios para você.',
              })
            }}
          >
            Solicite seu cartão Platinum Inter
          </Button>
        }
        <div className='row d-md-flex mt-4'>
          <div className='col-7 d-flex align-items-center pr-0'>
            <OrangeIcon className='m1-2' size='MD' icon='cashback' color='#00AA4F' />
            <p className='text-green--dark fs-12 lh-15 fs-lg-20 lh-lg-25 fw-600 mb-md-0 mt-3 mt-md-0 ml-2'>
              <span className='d-block'>Programa de</span> pontos gratuito
            </p>
          </div>
          <div className='col-5 d-flex align-items-center'>
            <p className='text-grayscale--500 fs-12 lh-15 fs-lg-20 lh-lg-25 fw-600 mb-0 mt-1'>
              <OrangeIcon className='mr-2' size='MD' icon='no-taxes' color='#161616' />Zero anuidade
            </p>
          </div>
        </div>
        { width < WIDTH_MD &&
          <ButtonLink
            href='https://intergo.app/c0ae23be'
            title='Solicite seu cartão Platinum Inter'
            className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-3'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_01',
                element_action: 'click button',
                element_name: 'Solicite seu cartão Platinum Inter',
                section_name: 'Cartão Platinum Inter.Mais benefícios para você.',
                redirect_url: 'https://intergo.app/c0ae23be',
              })
            }}
          >
            Solicite seu cartão Platinum Inter
          </ButtonLink>
        }
      </div>
      <ScrollTo
        to='#conheca-os-beneficios'
        section='dobra_01'
        sectionName='Cartão Platinum Inter. Mais benefícios para você.'
        elementName='Conheça os benefícios'
        styles='text-center d-none d-md-block'
      >
        <ScrollLink>
          <p className='text-orange--base mb-0 fs-18 lh-22'>Conheça os benefícios</p>
          <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
        </ScrollLink>
      </ScrollTo>
    </SectionComponent>
  )
}

export default GlobalAccountRemessas
