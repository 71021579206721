import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import Img from 'gatsby-image'
import useWidth from 'src/hooks/window/useWidth'
import SectionComponent from 'src/components/SectionComponent'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import usePageQuery from '../../pageQuery'

import { WIDTH_MD } from 'src/utils/breakpoints'

import { IOpenModalComponent } from '../../types'
import { Button, ButtonLink } from './../../styles'

const PagamentosSemCartao = ({ openModal }: IOpenModalComponent) => {
  const data = usePageQuery()
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='pagamentos-sem-tirar-seu-cartao'
      SectionStyles='bg-white align-items-center'
      minHeight={{ sm: '786px', md: '534px', lg: '580px', xl: '720px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 d-flex align-items-center justify-content-center justify-content-md-end order-md-last'>
        { width <= WIDTH_MD
          ? <Img
              fluid={data.pagamentosSemTirarSeuCartaoMobileTablet.fluid}
              alt='Pagamentos sem tirar seu cartão do bolso.'
            />
          : <Img
              fluid={data.pagamentosSemTirarSeuCartaoDesktopLgXl.fluid} alt='Pagamentos sem tirar seu cartão do bolso.'
              className='float-lg-right'
            />
        }
      </div>
      <div className='col-12 col-md-6 col-xl-5 mt-3 mt-md-0 pr-lg-0'>
        <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 fw-600 text-grayscale--500 font-sora mb-3'>
          Faça pagamentos sem tirar seu cartão Platinum do bolso
        </h2>
        <p className='fs-16 lh-19 text-grayscale--500 pr-lg-5'>
          Que tal pagar com o celular por aproximação? O <span className='d-lg-block d-xl-inline'>Cartão Platinum Inter é aceito nas principais</span>
          <Link
            to='/pra-voce/cartoes/carteiras-digitais/'
            title='Carteiras Digitais'
            target='_blank'
            className='fw-600'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_08',
                element_action: 'click button',
                element_name: 'carteiras digitais',
                section_name: 'Faça pagamentos sem tirar seu cartão Platinum do bolso',
              })
            }}
          >
            {' '}carteiras digitais
          </Link> disponíveis, como: Apple Pay, Google Pay e Samsung Pay!
        </p>
        <p className='fs-16 lh-19 text-grayscale--500 mb-0 pr-lg-5'>
          Conte também com o cartão virtual para comprar online com mais segurança e rapidez.
        </p>
        { width < WIDTH_MD
          ? (
            <ButtonLink
              href='https://intergo.app/c0ae23be'
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-4'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_08',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Faça pagamentos sem tirar seu cartão Platinum do bolso',
                  redirect_url: 'https://intergo.app/c0ae23be',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </ButtonLink>
          )
          : (
            <Button
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-4'
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_08',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Faça pagamentos sem tirar seu cartão Platinum do bolso',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </Button>
          )
        }
      </div>
    </SectionComponent>
  )
}

export default PagamentosSemCartao
