import React from 'react'

import SectionComponent from 'src/components/SectionComponent'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/utils/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { contentData } from './_contentData'

import { IOpenModalComponent } from '../../types'
import { CardOfertasEspeciais, Button, ButtonLink } from './../../styles'

type OfertasEspeciaisProps = {
  icon: string;
  title: string;
  description: string;
}

const OfertasEspeciais = ({ openModal }: IOpenModalComponent) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='ofertas-especiais'
      minHeight={{ sm: '680px', md: '450px', lg: '480px', xl: '600px' }}
    >
      <div className='col-12 col-md-6 col-xl-5 pr-lg-0'>
        <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mt-md-4 mb-3 text-grayscale--500 font-sora'>
          Aproveite as ofertas especiais Mastercard Platinum
        </h2>
        <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 mb-0'>
          Viva momentos únicos ao lado de quem você gosta, com os melhores recursos do nosso cartão Platinum.
        </p>
        { width >= WIDTH_MD &&
          <Button
            title='Solicite seu cartão Platinum Inter'
            className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-5'
            onClick={() => {
              openModal()
              sendDatalayerEvent({
                section: 'dobra_05',
                element_action: 'click button',
                element_name: 'Solicite seu cartão Platinum Inter',
                section_name: 'Aproveite as ofertas especiais Mastercard Platinum',
              })
            }}
          >
            Solicite seu cartão Platinum Inter
          </Button>
        }
      </div>
      <div className='col-12 col-md-6 col-xl-7 '>
        <DefaultCarousel
          sm={{ items: 1, partialVisibilityGutter: 116 }}
          md={{ items: 1, partialVisibilityGutter: 140 }}
          lg={{ items: 2, partialVisibilityGutter: 32 }}
          xl={{ items: 3, partialVisibilityGutter: 20 }}
        >
          {contentData.vantagens.map((item: OfertasEspeciaisProps) => (
            <div key={item.title}>
              <CardOfertasEspeciais
                className='d-flex flex-column justify-content-between align-items-start rounded-3 bg-grayscale--100 p-4 mr-3'
              >
                <OrangeIcon size='MD' icon={item.icon} color='#FF7A00' />
                <div className='mt-3'>
                  <p className='fs-16 lh-20 fw-600 text-grayscale--500 mb-2 font-sora'>{item.title}</p>
                  <p className='fs-14 lh-17 text-grayscale--400 mb-0'>{item.description}</p>
                </div>
              </CardOfertasEspeciais>
            </div>
            ),
          )}
        </DefaultCarousel>
        { width < WIDTH_MD &&
          <div className='mt-4'>
            <ButtonLink
              href='https://intergo.app/c0ae23be'
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none'
            >
              Solicite seu cartão Platinum Inter
            </ButtonLink>
          </div>
        }
      </div>
    </SectionComponent>
  )
}

export default OfertasEspeciais
