import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.div`
  padding: 45px 0 43px 0;
  @media ${device.desktopLG} {
    padding: 72px 0 50px 0;
  }
  @media ${device.desktopXL} {
    padding: 97px 0 66px 0;
  }

  .benefits {
    border-bottom: 1px solid #DEDFE4;

    &:last-child {
      border-bottom: none;
    }

    @media ${device.tablet} {
      border: none;
      border: 1px solid #DEDFE4;
      border-radius: 10px;
      height: 174px;
    }
    @media ${device.desktopLG} {
      height: 211px;
    }
  }
  a {
    @media ${device.tablet} {
      width: 456px!important;
      margin: 0 auto;
    }
  }
`
