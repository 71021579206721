import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      beneficiosCartaoPlatinumMobile: imageSharp(fluid: {originalName: { regex: "/beneficios-cartao-platinum-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      beneficiosCartaoPlatinumTablet: imageSharp(fluid: {originalName: { regex: "/beneficios-cartao-platinum-md/" }}) {
        fluid(maxWidth: 333, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      beneficiosCartaoPlatinumLG: imageSharp(fluid: {originalName: { regex: "/beneficios-cartao-platinum-lg/" }}) {
        fluid(maxWidth: 376, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      beneficiosCartaoPlatinumXL: imageSharp(fluid: {originalName: { regex: "/beneficios-cartao-platinum-xl/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoPlatinumComCashbackMobileTablet: imageSharp(fluid: {originalName: { regex: "/cartao-platinum-com-cashback-mobile-tablet/" }}) {
        fluid(maxWidth: 313, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      cartaoPlatinumComCashbackDesktopLgXl: imageSharp(fluid: {originalName: { regex: "/cartao-platinum-com-cashback-desktop-lg-xl/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pagamentosSemTirarSeuCartaoMobileTablet: imageSharp(fluid: {originalName: { regex: "/pagamentos-sem-tirar-seu-cartao-mobile-tablet/" }}) {
        fluid(maxWidth: 329, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      pagamentosSemTirarSeuCartaoDesktopLgXl: imageSharp(fluid: {originalName: { regex: "/pagamentos-sem-tirar-seu-cartao-desktop-lg-xl/" }}) {
        fluid(maxWidth: 453, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      descontosUnicosMobile: imageSharp(fluid: {originalName: { regex: "/descontos-unicos-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      descontosUnicosTablet: imageSharp(fluid: {originalName: { regex: "/descontos-unicos-tablet/" }}) {
        fluid(maxWidth: 762, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      descontosUnicosDesktopLG: imageSharp(fluid: {originalName: { regex: "/descontos-unicos-desktop-lg/" }}) {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      descontosUnicosDesktopXL: imageSharp(fluid: {originalName: { regex: "/descontos-unicos-desktop-xl/" }}) {
        fluid(maxWidth: 708, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
