
import React from 'react'
import FAQ from 'src/components/Faq'

import { SectionWrapper } from './../../styles'

type Faq = {
  faq: {question: string; answer: string}[];
}

const FAQComponent = (props: Faq) => {
  return (
    <SectionWrapper className='bg-grayscale--100 py-5'>
      <div className='container py-lg-4'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 font-sora mb-4'>
              Perguntas frequentes sobre o cartão de crédito Platinum Inter
            </h2>
            <FAQ
              answerData={props.faq} columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
              className='px-0'
            />
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default FAQComponent
