
export const contentData = {
  vantagens: [
    {
      icon: 'gift',
      title: 'Mastercard Surpreenda',
      description: 'Ganhe pontos a cada compra e troque por produtos, descontos e benefícios.',
    },
    {
      icon: 'preparando',
      title: 'Restaurantes',
      description: 'Resgate seus pontos do Mastercard Surpreenda para curtir com a família nos restaurantes participantes.',
    },
    {
      icon: 'coupon',
      title: 'Ofertas Especiais',
      description: 'Você terá acesso a ofertas especiais de parceiros Mastercard e poderá participar de sorteios exclusivos.',
    },
    {
      icon: 'ticket',
      title: 'Isenção de rolha',
      description: 'Aproveite o serviço de isenção de rolha Mastercard em vários restaurantes de todo o Brasil.',
    },
  ],
}
