import React from 'react'

import Link from 'src/components/GatsbyLinkWrapper/index'

import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import SectionComponent from 'src/components/SectionComponent'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { WIDTH_XL } from 'src/utils/breakpoints'

import { IOpenModalComponent } from '../../types'
import { Button } from './../../styles'

const ComoConseguirCartao = ({ openModal }: IOpenModalComponent) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='como-conseguir-o-cartao'
      SectionStyles={`pt-3 py-lg-0 ${width >= WIDTH_XL && 'bg-grayscale--100'}`}
      minHeight={{ sm: '480px', md: '381px', lg: '370px', xl: '600px' }}
    >
      <div className='col-12 col-xl-5'>
        <div className='col-12 px-0'>
          <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 mb-3 text-grayscale--500 font-sora'>
            Como conseguir o cartão Platinum Inter?
          </h2>
          <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500 mb-4'>
            Existem várias formas de conseguir um cartão Platinum no Inter. Escolha a que mais combina com você:
          </p>
        </div>
        <div className='col-12 pl-0 pr-xl-0 pt-md-2'>
          <div className='d-flex mb-3 align-items-center'>
            <div className='pr-3'>
              <OrangeIcon size='MD' icon='investments' color='#161616' />
            </div>
            <p className='fs-16 lh-19 text-grayscale--500 fw-600 mb-md-0 pt-lg-1'>
              Ter entre R$ 50 mil e R$ 250 mil investidos em fundos da Inter Invest, nossa plataforma de investimentos;
            </p>
          </div>
          <div className='d-flex mb-3 align-items-center'>
            <div className='pr-3'>
              <OrangeIcon size='MD' icon='wallet' color='#161616' />
            </div>
            <p className='fs-16 lh-19 text-grayscale--500 fw-600 mb-md-0 pt-lg-1'>
              Receber um salário maior ou igual a R$ 6 mil líquido por, no mínimo, 3 meses consecutivos;
            </p>
          </div>
          <div className='d-flex align-items-center'>
            <div className='pr-3'>
              <OrangeIcon size='MD' icon='card' color='#161616' />
            </div>
            <p className='fs-16 lh-19 text-grayscale--500 fw-600 mb-0 pt-lg-1'>
              Ser cliente do cartão Inter Gold e ter cada uma das últimas 4 faturas do cartão acima de R$ 5 mil.
            </p>
          </div>
        </div>
      </div>
      { width >= WIDTH_XL &&
        <div className='col-12 col-xl-5 pr-xl-0'>
          <h3 className='fs-24 lh-30 mb-3 text-grayscale--500 font-sora'>
            Caso se enquadre nos itens 1 ou 2, você também se torna cliente{' '}
            <Link
              to='/pra-voce/relacionamento/inter-one/'
              title='Inter One'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_06',
                  element_action: 'click button',
                  element_name: 'Inter One',
                  section_name: 'Como conseguir o cartão Platinum Inter?',
                })
              }}
            >
              Inter One
            </Link> e passa a ter um advisor exclusivo para te
            ajudar a conquistar uma carteira de investimentos cada vez mais robusta.
          </h3>
          <p className='fs-12 lh-15 text-grayscale--500 mb-0'>
            Para ter o cartão Platinum pelo valor das faturas, é preciso estar em dia com o pagamento delas, sem nenhum atraso.
            Além disso, o limite (acima de R$ 5 mil) precisa ter sido concedido pelo Inter na análise de crédito, sem contar o CDB Mais Limite.
          </p>
          <Button
            title='Solicite seu cartão Platinum Inter'
            className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-5'
            onClick={() => {
              openModal()
              sendDatalayerEvent({
                section: 'dobra_06',
                element_action: 'click button',
                element_name: 'Solicite seu cartão Platinum Inter',
                section_name: 'Como conseguir o cartão Platinum Inter?',
              })
            }}
          >
            Solicite seu cartão Platinum Inter
          </Button>
        </div>
        }
    </SectionComponent>
  )
}

export default ComoConseguirCartao
