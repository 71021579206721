import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Bread = styled.div`
  
  span {
    color: ${grayscale[500]} !important;

    a {
      color: ${grayscale[500]};
      opacity: 1 !important;

      &:hover {
        opacity: 0.6 !important;
      }
    }
  }
`

export const ScrollLink = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
`

export const FaresCard = styled.div`
  box-shadow: 0px 4px 8px -2px rgba(22, 22, 22, 0.08);
  border-radius: 8px;
  justify-content: center;
  display: flex;
  flex-direction: column;

  @media ${device.desktopLG} {
    min-height: 130px;
  }
`

export const CardOfertasEspeciais = styled.div`
  min-height: 300px;

  @media ${device.tablet}{
    min-height: 310px;
  }
  @media ${device.desktopLG}{
    min-height: 280px;
  }
  svg {
    width: 32px;
    height: 32px;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${orange.extra};

  @media ${device.tablet}{
    max-width: 456px;
  }

  &:hover {
    color: ${grayscale[400]};
  }
`

export const ButtonLink = styled.a`
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${orange.extra};

  &:hover {
    color: ${grayscale[400]};
  }
`

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;

  input {
    background-color: white;
  }
`
