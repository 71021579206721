import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import OrangeDS from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

const ProgramaBeneficios = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='bg-gray'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-md-32 fs-lg-40 lh-30 lh-md-40 lh-lg-50 fw-600 text-grayscale--500 text-md-center mb-3'>
              Programa de benefícios Inter
            </h2>
            <p className='fs-16 lh-19 text-grayscale--400 pr-lg-5 text-md-center'>
              Com o Inter Loop, você acumula 1 ponto a cada R$5,00 em compras no cartão de crédito para trocar por recompensas exclusivas. E o melhor: seus pontos que não expiram.
            </p>
            <p className='fs-10 fs-md-14 fs-xl-18 lh-12 lh-md-16 lh-lg-20 lh-xl-22 text-grayscale--400 text-md-center'>Para ganhar pontos, é necessário ter o débito automático de fatura ativo.</p>
          </div>
        </div>
        <div className='row mt-md-4 justify-content-md-between'>
          <div className='col-12 col-md-2 d-flex align-items-center align-items-md-start flex-md-column py-4 benefits px-md-3 px-lg-4'>
            <div className='mr-3 ml-md-0'><OrangeDS size='MD' color='#161616' icon='travel' /></div>
            <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mb-0 fw-600 mt-md-3'>
              <span className='d-block'>Milhas</span> TudoAzul
            </p>
          </div>
          <div className='col-12 col-md-2 d-flex align-items-center align-items-md-start flex-md-column py-4 benefits px-md-3 px-lg-4'>
            <div className='mr-3'><OrangeDS size='MD' color='#161616' icon='cashback' /></div>
            <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mb-0 fw-600 mt-md-3'>
              <span className='d-block'>Cashback</span> em conta
            </p>
          </div>
          <div className='col-12 col-md-2 d-flex align-items-center align-items-md-start flex-md-column py-4 benefits px-md-3 px-lg-4'>
            <div className='mr-3'><OrangeDS size='MD' color='#161616' icon='money-down' /></div>
            <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mb-0 fw-600 mt-md-3'>
              <span className='d-block'>Desconto</span> na fatura
            </p>
          </div>
          <div className='col-12 col-md-2 d-flex align-items-center align-items-md-start flex-md-column py-4 benefits px-md-3 px-lg-4'>
            <div className='mr-3'><OrangeDS size='MD' color='#161616' icon='shopping-bag' /></div>
            <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mb-0 fw-600 mt-md-3'>
              <span className='d-block'>Cashback</span> <span className='d-block'>extra no</span> Inter Shop
            </p>
          </div>
          <div className='col-12 col-md-2 d-flex align-items-center align-items-md-start flex-md-column py-4 benefits px-md-3 px-lg-4'>
            <div className='mr-3'><OrangeDS size='MD' color='#161616' icon='pig' /></div>
            <p className='fs-16 fs-lg-20 lh-20 lh-lg-25 text-grayscale--500 mb-0 fw-600 mt-md-3'>
              <span className='d-block'>Meu</span> Porquinho
            </p>
          </div>
          <Link
            className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mt-5 text-md-center fw-700'
            to='/pra-voce/cartoes/programa-de-pontos'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                element_name: 'Conhecer o Inter Loop',
                section_name: 'Programa de benefícios Inter',
                redirect_url: 'https://inter.co/pra-voce/cartoes/programa-de-pontos',
                c_page: window.location.href,
              })
            }}
          >
            Conhecer o Inter Loop
          </Link>
        </div>
      </div>
    </Section>
  )
}

export default ProgramaBeneficios
