import React, { useState } from 'react'
import Layout from 'src/layouts/BaseLayout'
import useWidth from 'src/hooks/window/useWidth'
import { Modal } from 'src/components/Modal'
import useDomReady from 'src/hooks/window/useDomReady'

import { ModalAccessAccountRight } from 'src/components/Layout/NewHeader/components/ModalAccessAccountRight/_index'
import CartaoPlatinumOpenAccountRightForm from 'src/components/UI/Forms/CartaoPlatinumOpenAccountRightForm'

import Hero from './sections/hero/_index'
import ProgramaBeneficios from './sections/programa-beneficios/_index'
import BeneficiosPlatinum from './sections/beneficios-platinum/_index'
import DescontosUnicos from './sections/descontos-unicos/_index'
import OfertasEspeciais from './sections/ofertas-especiais/_index'
import ComoConseguirCartao from './sections/como-conseguir-cartao/_index'
import Enquadramento from './sections/enquadramento/_index'
import PagamentosSemCartao from './sections/pagamentos-sem-cartao/_index'
import FAQ from './sections/Faq/_index'

import pageContextJSON from './pageContext.json'

import { WIDTH_XL } from 'src/utils/breakpoints'

const GlobalAccount = () => {
  const width = useWidth(300)

  const [ modalOpenYourAccount, setModalOpenYourAccount ] = useState(false)
  const [ openAccessAccount, setOpenAccessAccount ] = useState(false)

  const domReady = useDomReady()

  const openAccountModal = domReady && (
    <Modal isModalOpen={modalOpenYourAccount} setIsModalOpen={setModalOpenYourAccount} locationToRender={document.body}>
      <CartaoPlatinumOpenAccountRightForm
        closeModal={() => setModalOpenYourAccount(false)}
        openAccesAccountModal={setOpenAccessAccount} customOrigin='cartao-inter-platinum'
      />
    </Modal>
  )

  const accessAccountModal = domReady && (
    <Modal isModalOpen={openAccessAccount} setIsModalOpen={setOpenAccessAccount} locationToRender={document.body}>
      <ModalAccessAccountRight closeModal={() => setOpenAccessAccount(false)} />
    </Modal>
  )

  const handleClickOpenModal = () => {
    setModalOpenYourAccount(!modalOpenYourAccount)
  }

  return (
    <Layout pageContext={pageContextJSON}>
      {openAccountModal}
      {accessAccountModal}
      <Hero openModal={handleClickOpenModal} />
      <ProgramaBeneficios />
      <BeneficiosPlatinum openModal={handleClickOpenModal} />
      <DescontosUnicos openModal={handleClickOpenModal} />
      <OfertasEspeciais openModal={handleClickOpenModal} />
      <ComoConseguirCartao openModal={handleClickOpenModal} />
      { width < WIDTH_XL &&
        <Enquadramento openModal={handleClickOpenModal} />
      }
      <PagamentosSemCartao openModal={handleClickOpenModal} />
      <FAQ faq={pageContextJSON.structuredData.faq} />
    </Layout>
  )
}

export default GlobalAccount
