import React from 'react'
import SectionComponent from 'src/components/SectionComponent'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { IOpenModalComponent } from '../../types'
import { FaresCard, Button, ButtonLink } from './../../styles'

import { WIDTH_MD, WIDTH_LG, WIDTH_XL } from 'src/utils/breakpoints'

const BeneficiosPlatinum = ({ openModal }: IOpenModalComponent) => {
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionComponent
      id='conheca-os-beneficios'
      minHeight={{ sm: '902px', md: '701px', lg: '587px', xl: '720px' }}
    >
      <div className='col-12 col-lg-6 pr-md-0'>
        <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 font-sora mb-3 text-md-center text-lg-left'>
          Conheça os benefícios do Mastercard Platinum
        </h2>
        <p className='fs-16 lh-19 fs-md-18 lh-md-22 text-grayscale--400 mb-0 mb-lg-4 text-md-center text-lg-left'>
          Viaje com segurança e viva experiências incríveis nos seus destinos preferidos.
        </p>
        { width >= WIDTH_LG &&
          <Button
            title='Solicite seu cartão Platinum Inter'
            className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-2'
            onClick={() => {
              openModal()
              sendDatalayerEvent({
                section: 'dobra_03',
                element_action: 'click button',
                element_name: 'Solicite seu cartão Platinum Inter',
                section_name: 'Conheça os benefícios do Mastercard Platinum',
              })
            }}
          >
            Solicite seu cartão Platinum Inter
          </Button>
        }
      </div>
      <div className='col-12 col-lg-6 mt-4'>
        <div className='row mx-0'>
          <div className='col-12 px-0'>
            <div className='row'>
              <div className='col-12'>
                <FaresCard className='col-12 p-4 bg-white mb-4 mb-md-3'>
                  <h4 className='fs-16 lh-20 text-orange--extra mb-2 font-sora'>
                    Seguro médico e de automóvel em viagens
                  </h4>
                  <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                    Viaje com segurança utilizando o MasterAsssist Plus e o MasterSeguros de Automóveis.
                  </p>
                </FaresCard>
              </div>
              <div className='col-12'>
                <FaresCard className='col-12 p-4 bg-white mb-4 mb-md-3'>
                  <h4 className='fs-16 lh-20 text-orange--extra mb-2 font-sora'>Travel Rewards</h4>
                  <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                    Faça compras físicas e online nas maiores lojas do mundo pagando preços especiais.
                  </p>
                </FaresCard>
              </div>
            </div>
          </div>
          <div className='col-12 px-0'>
            <div className='row'>
              <div className='col-12'>
                <FaresCard className='col-12 p-4 bg-white mb-4 mb-md-3'>
                  <h4 className='fs-16 lh-20 text-orange--extra mb-2 font-sora'>Priceless Citiess</h4>
                  <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                    Viva experiências inspiradoras em eventos e participe de atividades exclusivas.
                  </p>
                </FaresCard>
              </div>
              <div className='col-12'>
                <FaresCard className='col-12 p-4 bg-white mb-4 mb-md-3 mb-xl-0'>
                  <h4 className='fs-16 lh-20 text-orange--extra mb-2 font-sora'>Concierge em viagens</h4>
                  <p className='fs-14 lh-17 text-grayscale--400 mb-0'>
                    Conte com um profissional dedicado a te auxiliar no planejamento de viagens, indicação de restaurantes e
                    eventos onde você estiver.
                  </p>
                </FaresCard>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 mt-3 mt-md-4 mt-lg-5 mb-lg-3 align-items-center d-flex flex-column'>
          { width < WIDTH_MD &&
            <ButtonLink
              href='https://intergo.app/c0ae23be'
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Conheça os benefícios do Mastercard Platinum',
                  redirect_url: 'https://intergo.app/c0ae23be',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </ButtonLink>
          }
          { width === WIDTH_MD && width < WIDTH_XL &&
            <Button
              title='Solicite seu cartão Platinum Inter'
              className='fs-14 lh-17 fw-600 text-white rounded-2 border-0 text-none mt-2'
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_03',
                  element_action: 'click button',
                  element_name: 'Solicite seu cartão Platinum Inter',
                  section_name: 'Solicite seu cartão Platinum Inter',
                })
              }}
            >
              Solicite seu cartão Platinum Inter
            </Button>
          }
        </div>
      </div>
    </SectionComponent>
  )
}

export default BeneficiosPlatinum
